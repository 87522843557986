import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { setPrescriptionData } from "../../../../../redux-store/slice/prescriptionDataSlice";

const useGetMedicalHandoubts = () => {
  const [medicalHandoubts, setMedicalHandoubts] = useState([]);
  const [isMedicalHandobts, setIsMedicalHandoubts] = useState(false);
  const [diagnosisTag, setDiagnosisTag] = useState([]);
  const [isDiagnosisTagLaoding, setIsDiagnosisLoading] = useState(false);
  const dispatch = useDispatch();

  const prescriptionData = useSelector((state) => state.prescriptionData);

  // const { diagnosis = [], medicalTags = [] } = prescriptionData || {};

  const getMedicalHandoubts = useCallback(async () => {
    setIsMedicalHandoubts(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(
        `https://v2.dev-api.parchaa.com/api/user/handouts/getHandouts/ASTHMA`,
        {
          // params: { query: inputValue },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMedicalHandoubts(res.data);
    } catch (error) {
      // toast.error(error?.response?.data?.message);
    }
    setIsMedicalHandoubts(false);
  }, []);

  const handleRemoveOption = (optionToRemove) => {
    const updatedHandouts = medicalHandoubts.filter(
      (option) => option.id !== optionToRemove.id
    );
    setMedicalHandoubts(updatedHandouts);
  };

  const getMedicalTags = useCallback(async () => {
    setIsDiagnosisLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(
        `https://v2.dev-api.parchaa.com/api/user/handouts/searchTags/ASTHMA`,
        {
          // params: { query: inputValue },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setDiagnosisTag(res.data);
    } catch (error) {
      // toast.error(error?.response?.data?.message);
    }
    setIsDiagnosisLoading(false);
  }, []);

  const getHandoutsById = useCallback(async (id) => {
    try {
      const token = localStorage.getItem("authToken");
      const res = await axios.get(
        `https://v2.dev-api.parchaa.com/api/user/handouts/getVeiwUrl/${id}`,
        {
          // params: { query: inputValue },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(res.data, "res");
      window.open(res.data, "_blank");
      // setDiagnosisTag(res.data);
    } catch (error) {
      // toast.error(error?.response?.data?.message);
    }
  }, []);

  useEffect(() => {
    getMedicalHandoubts();
  }, [getMedicalHandoubts]);

  useEffect(() => {
    getMedicalTags();
  }, [getMedicalTags]);

  const addHandoubtsToPrint = (item) => {
    dispatch(
      setPrescriptionData({
        ...prescriptionData,
        medicalHandoubts: [item, ...prescriptionData.medicalHandoubts],
      })
    );
  };

  const removeHandobutsToPrint = (item) => {
    const updatedHandouts = prescriptionData.medicalHandoubts.filter(
      (option) => option.id !== item.id
    );
    dispatch(
      setPrescriptionData({
        ...prescriptionData,
        medicalHandoubts: updatedHandouts,
      })
    );
  };

  const onAddStampToPrint = () => {
    dispatch(
      setPrescriptionData({
        ...prescriptionData,
        stamp: !prescriptionData.stamp,
      })
    );
  };

  const onAddSignatureToPrint = () => {
    dispatch(
      setPrescriptionData({
        ...prescriptionData,
        signature: !prescriptionData.signature,
      })
    );
  };

  // useEffect(() => {
  //   dispatch(
  //     setPrescriptionData({
  //       ...prescriptionData,
  //       medicalHandoubts: medicalHandoubts,
  //     })
  //   );
  // }, [medicalHandoubts]);

  return {
    getMedicalHandoubts,
    medicalHandoubts,
    isMedicalHandobts,
    getMedicalTags,
    diagnosisTag,
    isDiagnosisTagLaoding,
    getHandoutsById,
    handleRemoveOption,
    addHandoubtsToPrint,
    removeHandobutsToPrint,
    onAddSignatureToPrint,
    onAddStampToPrint,
  };
};

export default useGetMedicalHandoubts;
