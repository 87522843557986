import React, { memo, useContext } from "react";
import UpdatePassword from "../UpdatePassword";
import { Context } from "./DoctorAccount";

const DoctorMyProfile = () => {
  const doctorState = useContext(Context);
  const { doctorData } = doctorState || {};

  const handleChange = (e) => {
    const { name, value } = e.target || {};
    // setPersonalInfo((prev) => ({ ...prev, [name]: value }));
    console.log(name, value);
  };

  const {
    aboutMe,
    professionalDeatils = {},
    email,
    firstName,
    lastName,
  } = doctorData || {};

  // console.log(personalInfo, "personalaInfo");

  return (
    <div>
      <div
        className="m-8 px-4 py-4 rounded-md"
        style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
      >
        <div className="text-[#111928] mb-3 font-medium"> Personal Details</div>
        <div className="text-sm w-full flex gap-4">
          <div className="w-1/2">
            <div className="text-[#111928]">First Name</div>
            <div className="mt-1">
              <input
                type="text"
                name="firstName"
                onChange={handleChange}
                defaultValue={firstName}
                disabled
                className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="text-[#111928]">Last Name</div>
            <div className="mt-1">
              <input
                name="lastName"
                defaultValue={lastName}
                // onChange={handleChange}
                type="text"
                disabled
                className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
              />
            </div>
          </div>
        </div>
        <div className="text-sm my-4 w-full flex gap-4">
          <div className="w-1/2">
            <div className="text-[#111928]">Department</div>
            <div className="mt-1">
              <input
                type="text"
                name="department"
                // onChange={handleChange}
                defaultValue="Medical Staff"
                disabled
                className="focus:outline-none rounded-lg disabled:text-[#6B7280] ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="text-[#111928]">Role</div>
            <div className="mt-1">
              <input
                type="text"
                disabled
                name="role"
                defaultValue="Doctor"
                // onChange={handleChange}
                className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
              />
            </div>
          </div>
        </div>
        <div className="text-sm my-4 w-full flex gap-4">
          <div className="w-1/2">
            <div className="text-[#111928]">Email</div>
            <div className="mt-1">
              <input
                type="text"
                name="email"
                defaultValue={email}
                // onChange={handleChange}
                disabled
                className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
              />
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>

      <div
        className="m-8 px-4 py-4 rounded-md"
        style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
      >
        <div className="text-[#111928] mb-3 font-medium">Registration</div>
        <div className="text-sm my-4 w-full flex gap-4">
          <div className="w-1/2">
            <div className="text-[#111928]">Registration Number</div>
            <div className="mt-1">
              <input
                type="text"
                name="registrationNumber"
                // onChange={handleChange}
                disabled
                defaultValue={professionalDeatils.registrationNumber}
                placeholder="Add your registration number"
                className="focus:outline-none disabled:text-[#6B7280] font-normal rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
              />
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
        <div>
          <button
            disabled
            className="bg-[#1A56DB] disabled:bg-[#E5E7EB]
            disabled:border-[#E5E7EA] disabled:text-[#1F2A37] flex
            justify-center border border-[#1A56DB] item-center px-4 py-2
            rounded-lg text-sm text-[#FFFFFF]"
          >
            Verify
          </button>
        </div>
      </div>

      <div
        className="m-8 px-4 py-4 rounded-md"
        style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
      >
        <div className="text-[#111928] font-medium mb-3">About me</div>
        <div className="text-sm my-4 w-full flex">
          <textarea
            rows={5}
            placeholder="(optional)"
            name="aboutme"
            value={aboutMe}
            disabled
            // onChange={handleChange}
            className="focus:outline-none rounded-lg px-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
          />
        </div>
      </div>

      <div
        className="m-8 px-4 py-4 rounded-md"
        style={{ boxShadow: "0 0 4px rgb(44 62 80 / 20%)" }}
      >
        <div className="text-[#111928] font-medium mb-3">Language</div>
        <div className="text-sm my-4 w-full flex">
          <input
            placeholder="Language"
            name="language"
            value={professionalDeatils.language || "Hindi"}
            // onChange={handleChange}
            disabled
            className="focus:outline-none rounded-lg px-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2 w-full focus:border-[#006AF5]"
          />
        </div>
      </div>
      <UpdatePassword />
    </div>
  );
};

export default memo(DoctorMyProfile);
