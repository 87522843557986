import React, { useState } from "react";
import Modal from "../../../../../common-components/Modal";
import moment from "moment";
import useUpdateDoctorData from "../hooks/useUpdateDoctorData";

const AddQualificationModal = ({ isAddEducation, setIsAddEducation }) => {
  const [addEducation, setAddEducation] = useState({
    degree: "",
    institutionName: "",
    startDate: "",
    endDate: "",
    addressLine: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target || {};
    if (name === "startDate" || name === "endDate") {
      const newValue = moment(value).format("yyyy-MM");
      setAddEducation((prev) => ({ ...prev, [name]: newValue }));
    } else {
      setAddEducation((prev) => ({ ...prev, [name]: value }));
    }
  };

  console.log(addEducation, "add");
  const { onAddQualifaication = () => {}, isAddQualification = false } =
    useUpdateDoctorData() || {};

  return (
    <div>
      <Modal
        showModal={isAddEducation}
        modalWidth="w-1/2"
        modalHeight="min-h-1/2"
        onClose={() => setIsAddEducation(false)}
      >
        <div className="px-5 py-3 font-semibold text-[#222222]">Education</div>
        <div className="px-5">
          <div className="text-sm mb-2 w-full flex gap-4">
            <div className="w-1/2">
              <div className="text-[#111928]">Degree</div>
              <div className="mt-1">
                <input
                  name="degree"
                  value={addEducation.degree}
                  onChange={handleChange}
                  type="text"
                  className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-1.5 w-full focus:border-[#006AF5]"
                />
              </div>
            </div>
            <div className="w-1/2">
              <div className="text-[#111928]">School</div>
              <div className="mt-1">
                <input
                  type="text"
                  name="institutionName"
                  onChange={handleChange}
                  value={addEducation.institutionName}
                  className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-1.5 w-full focus:border-[#006AF5]"
                />
              </div>
            </div>
          </div>
          <div className="text-sm mb-2 w-full flex gap-4">
            <div className="w-1/2">
              <div className="text-[#111928]">Start Date</div>
              <div className="mt-1 flex gap-5">
                <input
                  name="startDate"
                  value={addEducation.startDate}
                  onChange={handleChange}
                  type="month"
                  className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-1.5 w-full focus:border-[#006AF5]"
                />
              </div>
            </div>
            <div className="w-1/2">
              <div className="text-[#111928]">End Date</div>
              <div className="mt-1 flex gap-5">
                <input
                  name="endDate"
                  value={addEducation.endDate}
                  onChange={handleChange}
                  type="month"
                  className="focus:outline-none disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-1.5 w-full focus:border-[#006AF5]"
                />
              </div>
            </div>
          </div>
          <div className="w-full text-sm mb-2">
            <div className="text-[#111928] mb-1">Address</div>
            <textarea
              value={addEducation.addressLine}
              name="addressLine"
              onChange={handleChange}
              className="focus:outline-none min-h-10 disabled:text-[#6B7280] rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-1.5 w-full focus:border-[#006AF5]"
            />
          </div>
          <div className="flex py-5 text-sm justify-between">
            <button
              onClick={() => setIsAddEducation(false)}
              className="border-[#D1D5DB] border rounded-lg text-[#444444] px-5 py-2"
            >
              Cancel
            </button>
            <button
              onClick={() => onAddQualifaication(addEducation)}
              disabled={isAddQualification}
              className="bg-[#3F83F8] rounded-lg disabled:text-[#6B7280] text-white px-5 py-2"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddQualificationModal;
